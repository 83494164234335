import { defineStore } from "pinia";
import { useNuxtApp } from '#app';

export const useUserStore = defineStore('user',{
    state:()=>{
        return {
            profile:{},
            token:'',
            isLoggedIn:false,
            isPreview:false
        }
    },
    actions:{
        setProfile(data){
            this.profile = {}
            this.profile = data
            localStorage.setItem('profile', JSON.stringify(data))
        },
        setAuth(token){
            this.token = token
            this.isLoggedIn = true
            localStorage.setItem('token',token)
            localStorage.setItem('isLoggedIn',true)

            // Connect to Socket.IO
            const { $socket } = useNuxtApp();

            if (!$socket.connected) {
                $socket.connect();
                localStorage.setItem('shouldReconnect', 'true'); // Save connection state
                console.log('Socket connection manually initiated');
              }
        },
        removeAuth(){
            // Disconnect from Socket.IO
            const { $socket } = useNuxtApp();

            if ($socket.connected) {
                $socket.emit('leave-room',this.profile.id)
                $socket.disconnect();
                localStorage.removeItem('shouldReconnect'); // Clear connection state
                console.log('Socket connection manually terminated');
              }

            this.profile = {}
            this.token = null,
            this.isLoggedIn = false
            localStorage.clear()
        },
        setPreviewAuth(token){
            sessionStorage.clear()
            this.token = token
            this.isLoggedIn = true
        },
        setPreviewProfile(data){
            this.profile = {}
            this.profile = data
            sessionStorage.setItem('profile', JSON.stringify(data))
        },
        resetUserStore(){
            this.profile={}
            this.token=''
            this.isLoggedIn=false
        },
        setPreviewMode(val){
            this.isPreview = val
        },
        removeGoogleToken(){
            this.profile.google_token = '';
            this.profilegoogle_token_type = '';
        }   
    },
    getters:{
        getUserProfile:(state)=>{
            return state.profile
        },
    },
    persist: true,
})