import { defineStore } from "pinia";

export const useStatsStore = defineStore('stats',{

    state:()=>{
        return {
            project_count:0,
            task_count:0,
            latest_tasks:[],
            stats:null,
            isAvailabe:false,
            chartData:{
                labels: [],
                datasets: [{
                    data: [],
                    backgroundColor: '#EB2F3C',
                    borderRadius: 6
                }],
            },
            cancelled_meetings_count:0,
            meetings_count:0,
            today_meetings:[]
        }
    },
    actions:{
        setStats(stats){
           this.project_count = stats.projects_count
           this.task_count = stats.tasks_count
           this.latest_tasks = stats.latest_tasks
           this.isAvailabe = true
           this.cancelled_meetings_count = stats.cancelled_meetings_count
           this.meetings_count = stats.meetings_count
           this.today_meetings = stats.today_meetings
        },  
        setChartData(label,dataset){
            this.chartData['labels'] = label
            this.chartData['datasets'][0].data = dataset
            console.log('storeStats',this.chartData)
        },
        resetStatsStore(){
            this.project_count=0
            this.task_count=0
            this.latest_tasks=[]
            this.stats=null
            this.isAvailabe=false
            this.chartData={
                labels: [],
                datasets: [{
                    data: [],
                    backgroundColor: '#EB2F3C',
                    borderRadius: 6
                }],
            }
            this.cancelled_meetings_count=0
            this.meetings_count=0
            this.today_meetings=[]
        }
    },
    getters:{}
})