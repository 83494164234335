export const useWallet = async () =>{

    const userStore = useUserStore()
    const store = useWalletStore()
    const config = useRuntimeConfig()
    const errorStore = useErrorStore()

    const getWallet = async()=>{
        try {
            const {data:wallet,message} = await useAxiosGet('wallet')
            store.setWallet(wallet)
            let setTimeoutValue
            clearInterval(store.intervalId);
            
            if(store.intervalId){
                clearInterval(store.intervalId);
            }
            
            // setTimeoutValue = setInterval(async ()=>{
            //     console.log('setInterval')
            //     await trackMinutes(userStore.profile.ssm_id)
            // },120000)

            store.setSetIntervalId(setTimeoutValue)

            return wallet
        } catch (error) {
            console.log("Wallet Error : ",error)
            errorStore.showError(error.message,true,'error')
        }
    }

    // const trackMinutes = async (clientId) => {

    //     const {data:track} = await useAxiosPost('wallet/exhaust-minutes',{
    //         clientId:clientId
    //     })
    //     console.log('TRack',track.minsRemaining)

    //     store.updateHours(track.minsRemaining)

    //     return true


    //     // const track = await useAxiosPost('wallet/exhaust-minutes',{
    //     //     minutesConsumed:204000
    //     // })
    //     // store.updateHours(track.minutes)
    //     // const response = await useAxiosForSSM(config.app.ssm_key,`GetReport`,{
    //     //     clients:[clientId]
    //     // })
    //     // if(response){
    //     //     let duration = response.charts.clients[0].duration
    //     //     const track = await useAxiosPost('/exhaust-minutes',{
    //     //         clientId:clientId
    //     //     })
    //     //     store.updateHours(track.minutes)
    //     // }
    // }

    const saveCard = async (pm_id) => {
        try {
            console.log('pm_id',pm_id)
            const {data:card,message}  = await useAxiosPost('stripe/card/save',{
                payment_method_id:pm_id
            })
            store.addCard(card)
            errorStore.showError(message,true,'success')
            return card
        } catch (error) {
            console.log("Wallet Error : ",error)
            errorStore.showError(error.message,true,'error')
        }
    }

    const getSavedCards = async () =>{
        try {
            const {data:savedCards,message} = await useAxiosGet('stripe/cards')
            store.setCards(savedCards)
            return savedCards
        } catch (error) {
            console.log("Wallet Error : ",error)
            errorStore.showError(error.message,true,'error')
        }
    }

    const setDeafaultCard = async (pm_id) =>{
        try {
            const {data:card,message} = await useAxiosPost('stripe/card/default',{
                payment_method_id:pm_id
            })
            await getSavedCards()
            errorStore.showError(message,true,'success')
            return card
        } catch (error) {
            console.log("Wallet Error : ",error)
            errorStore.showError(error.message,true,'error')
        }
    }

    const deleteCard = async (pm_id) => {
        try {
            const {data:card,message} = await useAxiosPost('stripe/card/delete',{
                payment_method_id:pm_id
            })
            await getSavedCards()
            errorStore.showError(message,true,'success')
            return card
        } catch (error) {
            console.log("Wallet Error : ",error)
            errorStore.showError(error.message,true,'error')
        }
    }

    const getPricing = async () => {
       try {
         const {data:pricing,message} = await useAxiosGet('pricing')
         store.setPricing(pricing)
         return pricing
       } catch (error) {
            console.log("Wallet Error : ",error)
            errorStore.showError(error.message,true,'error')
       }
    }
    
    const getPaymentIntent = async (reqBody) => {
        const intent = await useAxiosPost('stripe/create-intent',{...reqBody})
        return intent
    }

    const getTrasactions = async () =>{
        try {
            const {data:transactions,message} = await useAxiosGet(`wallet/transactions?page=1`)
            store.setTransactions(transactions)
            return transactions
        } catch (error) {
            console.log("Wallet Error : ",error)
            errorStore.showError(error.message,true,'error')
        }
    }

    const applyDiscount = async (code) => {
        try {
            const {data:coupon,message} = await useAxiosPost('discount/apply',{code:code.toUpperCase()})
            errorStore.showError(message,true,'success')
            return coupon
        } catch (error) {
            console.log("Wallet Error : ",error)
            errorStore.showError(error.message,true,'error')
        }
    }

    const autoPayToggle = async (body) => {
       try {
         const {data:result,message} = await useAxiosPost('wallet/toggle-autopay',body)
         await getWallet()
         errorStore.showError(message,true,'success')
         return
         // store.autoPayToggle(result)
       } catch (error) {
            console.log("Wallet Error : ",error)
            errorStore.showError(error.message,true,'error')
       }
    }

    return {
        autoPayToggle,
        applyDiscount,
        getTrasactions,
        getPaymentIntent,
        getPricing,
        deleteCard,
        setDeafaultCard,
        getSavedCards,
        getWallet,
        saveCard
    }
}
