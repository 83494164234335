import { loadStripe } from '@stripe/stripe-js';
import { defineNuxtPlugin,useRuntimeConfig } from '#app'

export default defineNuxtPlugin(async(nuxtApp) => {
  const config = useRuntimeConfig()

  const stripe = await loadStripe(config.public.stripe_publishable_key);
    console.log('Stripe : ',stripe)
  nuxtApp.provide('stripe', stripe)
})
