<template>
    <div class="dash-wrap min-h-dvh bg-dash-gradient bg-cover bg-left-top bg-repeat-y flex font-poppins">
        <div :class="['item-left w-3/4 md:w-[230px] shrink-0 fixed bg-white bg-opacity-60 md:bg-opacity-30 backdrop-blur-xl border-r border-white border-opacity-60 md:border-0 md:backdrop-blur-0 z-10 h-full md:h-auto md:bg-transparent md:static -translate-x-full transition md:translate-x-0', menu && 'translate-x-0']">
            <div class="sidebar-logo px-8 pt-5 pb-10 hidden md:block">
                <img src="/images/logo-text.png" alt="">
            </div>
            <div class="flex flex-col py-5 h-full md:h-[calc(100%_-_110px)] md:py-0 justify-between">
                <ul class="sidebar">
                    <li class="mb-3 last:mb-0" @click="toggleMenu()">
                        <NuxtLink no-prefetch to="/" class="py-3 px-5 mx-[11px] flex gap-2 items-center text-inactiveTabs">
                            <Icon name='ph:squares-four-light' size="18" />
                            <span class="md:text-sm">
                                Dashboard
                            </span>
                        </NuxtLink>
                    </li>
                    <li class="mb-3 last:mb-0" @click="toggleMenu()">
                        <NuxtLink no-prefetch to="/project" class="py-3 px-5 mx-[11px] flex gap-2 items-center text-inactiveTabs">
                            <Icon name='mynaui:folder-two' size="18" />
                            <span class="md:text-sm">
                                Projects
                            </span>
                        </NuxtLink>
                    </li>
                    <li class="mb-3 last:mb-0" @click="toggleMenu()">
                        <NuxtLink no-prefetch to="/training-videos" class="py-3 px-5 mx-[11px] flex gap-2 items-center text-inactiveTabs">
                            <Icon name='mynaui:play-circle' size="18" />
                            <span class="md:text-sm">
                                Training Videos
                            </span>
                        </NuxtLink>
                    </li>
                    <li class="mb-3 last:mb-0" @click="toggleMenu()">
                        <NuxtLink no-prefetch to="/activity" class="py-3 px-5 mx-[11px] flex gap-2 items-center text-inactiveTabs">
                            <Icon name='uim:analytics' size="18" />
                            <span class="md:text-sm">
                               Activity
                            </span>
                        </NuxtLink>
                    </li>
                    <li class="mb-3 last:mb-0" @click="toggleMenu()">
                        <NuxtLink no-prefetch to="/calendar" class="py-3 px-5 mx-[11px] flex gap-2 items-center text-inactiveTabs">
                            <Icon name='mynaui:calendar' size="18" />
                            <span class="md:text-sm">
                                Calendar
                            </span>
                        </NuxtLink>
                    </li>
                    <li class="mb-3 last:mb-0" @click="toggleMenu()">
                        <NuxtLink no-prefetch to="/help-desk" class="py-3 px-5 mx-[11px] flex gap-2 items-center text-inactiveTabs">
                            <Icon name='mynaui:question-square' size="18" />
                            <span class="md:text-sm">
                                Help Desk
                            </span>
                        </NuxtLink>
                    </li>
                    <li class="mb-3 last:mb-0" @click="toggleMenu()">
                        <NuxtLink no-prefetch to="/chat" class="py-3 px-5 mx-[11px] flex gap-2 items-center text-inactiveTabs">
                            <Icon name='mynaui:message-dots' size="18" />
                            <span class="md:text-sm">
                                Chat
                            </span>
                        </NuxtLink>
                    </li>
                    <li class="mb-3 last:mb-0" @click="toggleMenu()" v-if="user.profile.account_type == 'client'">
                        <NuxtLink no-prefetch to="/wallet" class="py-3 px-5 mx-[11px] flex gap-2 items-center text-inactiveTabs">
                            <Icon name='solar:wallet-2-linear' size="18" />
                            <span class="md:text-sm">
                                Wallet
                            </span>
                        </NuxtLink>
                    </li>
                    <li class="mb-3 last:mb-0" @click="toggleMenu()">
                        <NuxtLink no-prefetch to="/settings" class="py-3 px-5 mx-[11px] flex gap-2 items-center text-inactiveTabs">
                            <Icon name='mynaui:cog' size="18" />
                            <span class="md:text-sm">
                                Settings
                            </span>
                        </NuxtLink>
                    </li>
                </ul>
                <div
                    class="flex items-center gap-2 p-2 mx-3 bg-white bg-opacity-20 border border-white border-opacity-30 rounded-2xl" @click="toggleMenu()">
                    <img :src="user.profile.profile ? `${config.public.s3_url}/${config.public.avtar_bucket}/${user.profile.profile}` : `${config.public.s3_url}/${config.public.avtar_bucket}/${config.public.default_avatar}` " class="w-10 h-10 object-cover rounded-md flex-shrink-0" alt="">
                    <div class="flex items-center justify-between w-full">
                        <NuxtLink no-prefetch to="/settings">
                            <p class="text-primary leading-none font-medium truncate max-w-28">{{ user.profile.first_name  }}</p>
                            <p class="flex items-center text-xxs font-medium gap-1 text-themegray before:contents-[''] before:w-2 before:h-2 before:rounded-full before:bg-complete">
                                Online</p>
                        </NuxtLink>
                        <Button buttonClass="bg-transparent !p-0 border-0 !text-secondary" loadClass="!stroke-secondary" :loading="logoutLoading" @click="logoutBtn" class="text-overdue cursor-pointer">
                            <Icon name='material-symbols:logout-rounded' size="24" />
                        </Button>
                        
                    </div>
                </div>
            </div>
        </div>
        <div :class="['menu-backdrop fixed bg-black bg-opacity-10 backdrop-blur-sm w-full h-full top-0 left-0 md:hidden z-[3] transition-all', menu ? 'opacity-100 visible' : 'opacity-0 invisible']" @click="toggleMenu()"></div>
        <div class="item-right w-full">
            <div
                class="top-nav px-3 md:px-7 py-5 flex items-center justify-between flex-row-reverse relative md:static">
                <ul class="flex gap-4 md:gap-7 justify-end">
                    <li class="md:relative">
                        <div class="relative cursor-pointer flex justify-center items-center gap-2" @click="googleAuthentication">
                            <!-- <span class="text-xxs min-w-4 h-4 px-1 rounded-full bg-secondary text-white flex-shrink-0 inline-flex justify-center items-center font-medium absolute -top-1 left-4 leading-none">
                                <Icon name='material-symbols:verified-outline' size="16" /></span> -->
                                <Icon name='mingcute:google-fill' size="24" />
                                <span class="">{{user.profile.google_token ? 'Disconect':'Connect'}}</span>
                            </div>
                    </li>
                    <li class="md:relative">
                        <div class="relative cursor-pointer" @click="goToChat">
                            <span class="text-xxs min-w-4 h-4 px-1 rounded-full bg-secondary text-white flex-shrink-0 inline-flex justify-center items-center font-medium absolute -top-1 left-4 leading-none">
                                {{ gerUnreadMessages() }}</span>
                            <Icon name='mynaui:message-dots' size="24" />
                        </div>
                    </li>
                    <li class="md:relative" ref="dropdownContainer">
                        <div class="relative cursor-pointer" @click="toggleDropdown()">
                            <span class="text-xxs min-w-4 h-4 px-1 rounded-full bg-secondary text-white flex-shrink-0 inline-flex justify-center items-center font-medium absolute -top-1 left-3 leading-none">
                                {{ notificationStore.getUnreadCount }}
                            </span>
                            <Icon name='mynaui:bell' size="24" />
                        </div>
                        <ul :class="[' border border-lightgray notification fixed md:absolute bottom-0 md:bottom-[unset] right-0 w-full md:w-[400px]  bg-white rounded-tl-lg opacity-100 visible rounded-tr-lg md:rounded-lg z-[4] translate-y-0 md:!-translate-y-0 transition-all', !showDropdown && 'opacity-0 invisible !translate-y-full']">
                            <li class="py-3 px-3 border-b flex items-center justify-between gap-3"><span class="font-medium">Notifications</span> 
                            <button class="text-secondary text-sm" @click="readAllNotificationMessages" >Read All</button></li>
                            <li v-if="notificationStore.notifications.length > 0" class="px-2 py-3 h-[75vh] overflow-auto custom-scrollbar md:h-auto md:max-h-[40vh]">
                                
                                <div v-for="(notification,index) in notificationStore.notifications" :key="index" :class="['noti_wrap flex items-start justify-between gap-2 p-2 mb-2 border-b border-[#E3E3E3] last:border-0 last:mb-0 cursor-pointer',notification.is_read ? 'opacity-60':'']" @click="markAsRead(notification.id,notification.is_read,index)">
                                    <div class="flex items-start gap-2 w-full">
                                        <div
                                        class="icon w-7 h-7 bg-overdue bg-opacity-20 text-overdue text-lg flex justify-center items-center rounded-full flex-shrink-0">
                                        <Icon :name="getIconByType(notification.type)" size="18" />
                                        </div>
                                        <div class="content w-full">
                                            <div class="flex items-center justify-between mb-1">
                                                <p class="text-sm capitalize ">{{ notification.title }}</p>
                                                <span class="time text-xxs text-themegray flex-shrink-0">
                                                    {{ getDateFromNow(notification.created_at) }}
                                                </span>
                                            </div>
                                            <p class="text-xs text-gray-600">
                                                {{notification.message}}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="noti_wrap flex items-start justify-center gap-2 px-2 py-28 mb-2 border-b text-themegray text-sm last:border-0 last:mb-0" v-else>
                                No Notifications
                            </li>
                        </ul>
                        <div :class="['notification-backdrop bg-black bg-opacity-50 backdrop-blur-sm fixed z-[3] top-0 left-0 h-full w-full opacity-0 invisible transition-all md:hidden', showDropdown && '!opacity-100 !visible']" @click="toggleDropdown()"></div>
                    </li>
                    
                    <li class="block md:hidden cursor-pointer" @click="toggleMenu()">
                        <Icon :name='menu ? "mynaui:x" : "mynaui:menu"' size="24" />
                    </li>
                </ul>
                <div class="sidebar-logo w-24 block md:hidden">
                    <NuxtLink no-prefetch to="/">
                        <img src="/images/logo-text.png" alt="">
                    </NuxtLink>
                </div>
            </div>
            <div :class="['main-dash h-[calc(100vh_-_67px)] overflow-y-auto overflow-x-hidden bg-white bg-opacity-20 md:border border-t border-white border-opacity-40 md:rounded-tl-[20px] custom-scrollbar relative md:p-8', currentRoute.href !== '/chat' && 'p-3']">

                <slot />
            </div>
        </div>
    </div>
    <!-- <Toast v-if="show">
      This is a custom toast message!
    </Toast>
    <button @click="showToast" class="bg-blue-500 text-white py-2 px-4 rounded">
      Show Toast
      
    </button> -->
    <Toast position="right-top" :isDark="true" :type="errorStore.error.type" :description="errorStore.error.message"
    :isShow="errorStore.error.isShow" v-if="errorStore.error.isShow" />
</template>


<script setup>

const { logout,logoutAllDevices,getMyProfile,resetAllStores,googleSignIn,googleRevoke } = await useAuth();
const { getAssignedAssociates,getAllAssociates } = await useProject();
const {getChannelsAndSubscribe,unSubscribeChannels,getUnreadMessages} = await useChat();
const { getWallet } = await useWallet();
const {getNotifications,readNotifications,readAllNotifications} = await useNotification()
const { getStats,getChart } = await useStats()

const config = useRuntimeConfig()
const router = useRouter()
const { currentRoute } = router;
const dayjs = useDayjs()

const errorStore = useErrorStore()
const user = useUserStore()
const walletStore = useWalletStore()
const chatStore = useChatStore()
const notificationStore = useNotificationStore()

const menu = ref(false);
const showDropdown = ref(false);
const dropdownContainer = ref(null);
const logoutLoading = ref(false);

const goToChat = () => {
    navigateTo('/chat')
}

const toggleMenu = () => {
    menu.value = !menu.value;
}

const toggleDropdown = () => {
  showDropdown.value = !showDropdown.value;
}

const handleClickOutside = (event) => {
  if (dropdownContainer.value && !dropdownContainer.value.contains(event.target)) {
    showDropdown.value = false;
  }
};

const gerUnreadMessages = () => {
    const sum = Object.values(chatStore.unread_messages)
    .reduce((acc, val) => {
        return acc + val.length
    }, 0);
    const displayValue = sum > 99 ? '99+' : sum.toString();
    return displayValue;
}

// const tokenPromise = inject('tokenPromise');
// const tokenPromiseResolve = inject('tokenPromiseResolve');
const { $tokenPromise,$tokenPromiseResolve,$socket } = useNuxtApp(); // Inject the tokenPromise

onMounted(async () => {
    console.log('isPreview Onmounter Enter',user.isPreview)
    document.addEventListener('click', handleClickOutside);
    if(user.isPreview){
        await $tokenPromise; // Await the promise's resolution
    }
    else{
        console.log('Preview ELSE')
        $tokenPromiseResolve()
    }

    const [profile, associates, assignedAssociates] = await Promise.all([
      getMyProfile(),
      getAllAssociates(),
      getAssignedAssociates(),
    ]);

    setTimeout(async()=>{
        await unSubscribeChannels()
        await getChannelsAndSubscribe()
        await getNotifications()
        await getUnreadMessages()
    },3000)

    if(user.profile.account_type =="client"){
        await getWallet()
    }

    // Check if socket should reconnect on page load
    if (localStorage.getItem('shouldReconnect') === 'true') {
        $socket.emit('leave-room',user.profile.id)
        $socket.connect();
        $socket.emit('join-room',user.profile.id)
        $socket.on('wallet:update',({balance})=>{
            console.log('wallet:update',balance)
            walletStore.updateHours(balance)
        })
    }
});

onUnmounted(async() => {
    document.removeEventListener('click', handleClickOutside);
    await unSubscribeChannels()
    resetAllStores()
});

const logoutBtn = async () => {
    try{
        logoutLoading.value = true;
        await logoutAllDevices();
    }
    catch(error){
        console.log('LogoutBtn : ',error)
    }
    finally{
        logoutLoading.value = false;
        router.push('/login')
    }
}

const getIconByType = (type) => {
    switch (type) {
        case 'project':
            return 'mynaui:folder-two';
        case 'task':
            return 'hugeicons:task-01';
        case 'chat':
            return 'mynaui:message-dots';
        case 'meeting':
            return 'hugeicons:briefcase-01';
        case 'wallet':
            return 'solar:wallet-2-linear';
        case 'connection':
            return 'mynaui:user';
        default:
            return '';
    }
}

function getDateFromNow(date) {
    const now = dayjs();
    const diffInSeconds = now.diff(date, 'second');

    if (diffInSeconds < 60) {
        return 'just now';
    }

    return dayjs(date).fromNow();
}

const markAsRead = async(notificationId,notificationReadStatus,index)=>{
    if(!notificationReadStatus){
        await readNotifications(notificationId,index);
    }
    return
}

const readAllNotificationMessages = async() => {
    alert('readAllNotificationMessages')
    await readAllNotifications();
}

// const truncateString = (str, firstCharCount = str.length, endCharCount = 0, dotCount = 3) => {
//   if (str.length <= firstCharCount + endCharCount) {
//     return str; // No truncation needed
//   }
// }

const googleAuthentication = async() => {
    if(!user.profile.google_token){
        const url = await googleSignIn()
        window.open(url, '_blank');
    }
    else{
        await googleRevoke()
        await getMyProfile()
    }
}

</script>