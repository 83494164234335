import { useUserStore } from '~/stores/user';
import { useWalletStore } from '~/stores/wallet';
import { useRouter } from '#app'; // Import useRouter from Nuxt 3
import { useRuntimeConfig } from '#app'; // Import useRuntimeConfig from Nuxt 3

export default defineNuxtPlugin(nuxtApp => {
    console.log('Listener Plugin');
    const config = useRuntimeConfig();
    const router = useRouter();
    const user = useUserStore();

    // Promise to resolve when the token is set
    let tokenPromiseResolve;
    const tokenPromise = new Promise(resolve => {
        tokenPromiseResolve = resolve;
        console.log('Token promise initialized');
    });

    const currentDomain = typeof window !== 'undefined' ? window.location.origin : '';
    const adminDomain = config.public.admin_dashboard_url;
    const appDomain = config.public.frontend_url;
    const allowedOrigins = [adminDomain, appDomain]; // Define your domains

    const handleMessage = async (event) => {
        console.log('Enter Event');
        const origin = event.origin;

        console.log('Received message from origin:', origin);

        // Validate origin
        if (!allowedOrigins.includes(origin)) {
            console.log("Invalid origin:", origin);
            return;
        }

        const eventData = event.data;
        const eventType = eventData.event_type;

        switch (eventType) {
            case "preview":
                console.log("Handling preview event");
                sessionStorage.setItem("isPreview", true);
                sessionStorage.setItem("token", eventData.data.token);
                delete eventData.data.token;
                delete eventData.event_type;
                sessionStorage.setItem("isLoggedIn", true);

                if (sessionStorage.getItem('token')) {
                    user.setPreviewProfile(eventData.data);
                    user.setPreviewMode(true);
                    console.log('Resolving token promise');
                    tokenPromiseResolve('from preview');
                    router.push('/');
                }
                break;

            case "close":
                console.log("Handling close event");
                const walletStore = useWalletStore(); // Ensure this import is correct
                if (walletStore.intervalId) {
                    clearInterval(walletStore.intervalId);
                }
                user.resetUserStore();
                sessionStorage.clear();
                localStorage.clear();
                window.parent.postMessage({ event_type: 'close_preview' }, origin);
                break;

            default:
                console.log("Unhandled event:", eventType);
                break;
        }
    };

    nuxtApp.provide('tokenPromise', tokenPromise);
    nuxtApp.provide('tokenPromiseResolve', tokenPromiseResolve);

    if (typeof window !== 'undefined') {
        window.addEventListener("message", handleMessage);
        console.log('afterMessage LIstener')
        nuxtApp.hook('app:destroyed', () => {
            console.log('app:destroyed')
            window.removeEventListener("message", handleMessage);
        });
    }
});