import { io } from 'socket.io-client';
import { useRuntimeConfig } from '#app'; // Import useRuntimeConfig from Nuxt 3

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();
  console.log('socket.io-client',config.public.socket_url)
  const socket = io(config.public.socket_url,{
    autoConnect: false,            // Prevent auto-connect on initialization
    reconnection: true,             // Enable reconnection
    reconnectionAttempts: Infinity, // Try to reconnect indefinitely
    reconnectionDelay: 1000,        // Initial delay of 1 second for reconnections
    reconnectionDelayMax: 5000,     // Maximum delay of 5 seconds between reconnections
    timeout: 20000                  // Connection timeout of 20 seconds
  });

  socket.on('connect',()=>{
    console.log('Socket Connection established')
  })

  // Error handling
  socket.on('error', (error) => {
    console.error('Connection error:', error.message);
  });

  // Make the socket instance available globally in Nuxt
  nuxtApp.provide('socket', socket);
});
